<route>
{
  "meta": {
    "permission": [
      "auth.view_group"
    ]
  }
}
</route>

<template>
  <div class="p-2">
    <addUserGroup v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <i-table
      :title="$tc('roles', 2)"
      :headers="headers"
      app="auth.group"
      api="role"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
      param="permissions"
      otherBtn
    >
      <template v-slot:otherBtn="{ item }">
        <i-btn
          v-if="groupBtn.contenttype"
          :title="$tc('roles_areas', 1)"
          outlined
          classes="mx-1"
          color="warning"
          icon="fa-map"
          @click="changeRoute(item)"
        />
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import addUserGroup from '../../components/roles/modal.vue'
export default {
  components: {
    addUserGroup
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text:
            this.groupBtn.delete ||
            this.groupBtn.edit ||
            this.groupBtn.contenttype
              ? this.$t('action')
              : '',
          value:
            this.groupBtn.delete ||
            this.groupBtn.edit ||
            this.groupBtn.contenttype
              ? 'actions'
              : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    groupBtn() {
      return {
        delete: this.getPermissions([`auth.delete_group`]) || this.isAdmin,
        edit: this.getPermissions([`auth.change_group`]) || this.isAdmin,
        contenttype:
          this.getPermissions([`contenttypes.view_contenttype`]) || this.isAdmin
      }
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    changeRoute(item) {
      this.$router.push({
        name: 'roles-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  }
}
</script>
